import { string } from 'yup';

export const englishStringRegEx = /^[A-Za-z0-9\s!@#$%^.,%?&*()_+-]+$/;

export const englishString = string().matches(
  englishStringRegEx,
  'English letters only'
);

export const macAddressRegex = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;

export const isRequired = ' is a required field';

export const urlRegex =
  /^(https?:\/\/)?([\wа-яА-ЯёЁ.+-]+)\.([a-zа-яА-ЯёЁ]{2,})([/\wа-яА-ЯёЁ.+-]*)*(\?[;&\wа-яА-ЯёЁ+=%_.-]*)?(#[\wа-яА-ЯёЁ+-]*)?$/;
