/* eslint-disable @typescript-eslint/no-explicit-any */
import ClearIcon from '@mui/icons-material/Clear';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as React from 'react';

type Props = {
  questionText?: string;
  dialogBody?: string | React.ReactNode;
  actionText?: string | React.ReactNode;
  actionColor:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  open: boolean;
  setOpen?: any;
  withCancelBtn?: boolean;
  handleSubmit?: any;
};

export const CustomDialog: React.FC<Props> = ({
  questionText,
  actionText,
  actionColor,
  open,
  setOpen,
  withCancelBtn = true,
  handleSubmit,
  dialogBody,
}) => {
  const handleClose = () => {
    if (setOpen) setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            minWidth: 300,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px 25px 30px 25px',
            borderRadius: '8px',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              margin: 1,
            }}
          >
            <ClearIcon />
          </IconButton>
          <Typography
            variant="h3"
            sx={{ marginTop: '30px', textAlign: 'center' }}
          >
            {questionText}
          </Typography>
          {dialogBody && dialogBody}
          <Box sx={{ marginTop: '20px', gap: '12px', display: 'flex' }}>
            {withCancelBtn && (
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
            )}

            {actionText && (
              <Button
                variant="contained"
                color={actionColor}
                onClick={() => {
                  if (setOpen) setOpen(false);
                  if (handleSubmit) handleSubmit();
                }}
                autoFocus
              >
                {actionText}
              </Button>
            )}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
