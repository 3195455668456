import { Vehicle } from '@mgk-eld/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface VehicleState {
  vehicles: Vehicle[];
}

const initialState: VehicleState = {
  vehicles: [],
};

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setVehicles: (state, action: PayloadAction<Vehicle[]>) => {
      state.vehicles = action.payload;
    },
  },
});

export const { setVehicles } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
