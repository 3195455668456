export const isStage =
  window.location.host.includes('stage') ||
  window.location.host.includes('localhost');

const prefix = isStage ? 'stage.' : '';
const https = `http://127.0.0.1:8005/api/`;
const socket = `wss://127.0.0.1:8005/ws/`;
const trackingApp = `https://${prefix}tracking.mgkeld.com/#/`;

export const PAYMENT_KEY = isStage
  ? 'pk_test_tsYdWwdC3hMhKXnn4OvQci4M00MbekmSVe'
  : 'pk_live_51OaZeDBP0zdhh7ZnlDLR9RCHoUnf69s6pN6bnsfSH7ziB02iguli5TLKjpV0Mn5il2Tbrkrn5BNsq0WnkGIGaLVi00Mbhzs3LC';

export const baseUrl = {
  https,
  socket,
  trackingApp,
};

console.log('api.ts', baseUrl, isStage);
