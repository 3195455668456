import styled from '@emotion/styled';
import { InputLabel, SelectChangeEvent, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

type Props = {
  options: string[];
  state: string[];
  setState: (value: string[]) => void;
};

const ReliableMenuItem = styled(MenuItem)`
  height: 36px;
  background-color: white !important;
  :hover {
    background-color: #e6eff7 !important;
  }
`;

export function MultiCheckBoxSelect({ options, state, setState }: Props) {
  const handleChangeStatus = (event: SelectChangeEvent<typeof state>) => {
    const {
      target: { value },
    } = event;
    setState(typeof value === 'string' ? value.split(',') : value);
  };
  return (
    <FormControl id="state" size="small" sx={{ width: '264px' }}>
      <InputLabel id="state">
        <Typography component="span">Status</Typography>
      </InputLabel>
      <Select
        multiple
        id="state"
        label="Status"
        value={state}
        renderValue={(state) => state.join(', ')}
        onChange={handleChangeStatus}
      >
        {options.map((i) => (
          <ReliableMenuItem key={i} value={i}>
            <Checkbox size="small" checked={state.indexOf(i) > -1} />
            <ListItemText primary={i} />
          </ReliableMenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
