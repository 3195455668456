import {
  PageOptions,
  Vehicle,
  axiosInstance,
  baseUrl,
  exportExcel,
} from '@mgk-eld/utils';

const endPoint = `${baseUrl.https}vehicles`;
export interface GetVehicleParams extends PageOptions {
  unit_number?: string;
  company_id?: string | number;
  include_deleted?: boolean;
}

const getVehicles = (params: GetVehicleParams) => {
  return axiosInstance.get(`${baseUrl.https}vehicles`, {
    params,
  });
};

const getVehicleById = (id: number | string) =>
  axiosInstance.get(`${endPoint}/${id}`);

const deleteVehicle = ({
  vehicleId,
  company_id,
  deleted,
}: {
  vehicleId: number;
  deleted: boolean;
  company_id: number;
}) => {
  return axiosInstance.delete(`${endPoint}/${vehicleId}`, {
    params: { deleted, company_id },
  });
};

const updateVehicle = (vehicle: Partial<Vehicle>, id: number | string) => {
  return axiosInstance.put(`${endPoint}/${id}`, vehicle);
};
const createVehicle = (vehicle: any) => {
  return axiosInstance.post(`${endPoint}`, vehicle);
};

const downloadXLSX = async (company_id: string | number) => {
  exportExcel(
    `${endPoint}/download/list`,
    { company_id },
    'vehicles.xlsx',
    axiosInstance.defaults.headers.common
  );
};

const vehiclesApi = {
  getVehicles,
  getVehicleById,
  createVehicle,
  updateVehicle,
  deleteVehicle,
  downloadXLSX,
};

export default vehiclesApi;
