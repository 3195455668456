import { CompanyShort } from '@mgk-eld/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CompaniesState {
  companiesList: CompanyShort[];
  selectedId: number | null;
  selectedCompany: CompanyShort | null;
}

const initialState: CompaniesState = {
  companiesList: [],
  selectedId: null,
  selectedCompany: null,
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setSelectedCompanyId: (state, action: PayloadAction<number | null>) => {
      state.selectedId = action.payload;
      state.selectedCompany = state.companiesList.find(
        (c) => c.id === action.payload
      ) as CompanyShort;
    },
    setCompaniesList: (state, action: PayloadAction<CompanyShort[]>) => {
      state.companiesList = action.payload;
    },
  },
});

export const { setSelectedCompanyId, setCompaniesList } =
  companiesSlice.actions;

export default companiesSlice.reducer;
