import { CompanyShort } from '@mgk-eld/utils';
import companiesApi from '../../services/companies';
import { AppThunk } from '../store';
import { fetchAndSetVehicles } from '../vehicles/thunk';
import { setCompaniesList, setSelectedCompanyId } from './slice';
import { fetchPaymentInfo } from '../subscription/thunks';

export const fetchCompanies = (): AppThunk => async (dispatch, getState) => {
  const { data } = await companiesApi.getCompanies({
    size: 100,
    page: 1,
    include_deleted: true,
  });

  if (data?.items) {
    dispatch(setCompaniesList(data.items));
    const selectedCompany = Number(
      window.localStorage.getItem('selectedCompany')
    );
    const isSelectedExistInList = data.items.find(
      (c: any) => c.id === selectedCompany
    );

    if (!isSelectedExistInList) {
      const newId =
        data.items.filter((d: CompanyShort) => !d.deleted)[0]?.id || null;
      dispatch(changeSelectedCompany(newId));
    } else dispatch(changeSelectedCompany(selectedCompany));
  }
};

export const changeSelectedCompany =
  (company_id: number | null): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setSelectedCompanyId(company_id));
    if (company_id) {
      window.localStorage.setItem('selectedCompany', company_id.toString());
      dispatch(fetchAndSetVehicles(company_id));
      dispatch(fetchPaymentInfo());
    } else window.localStorage.removeItem('selectedCompany');
  };
