import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { useAppSelector } from '../../hooks/reduxHooks';
import { getSelectedCompanyId } from '../../redux/companies/selectors';
import errorsApi, { ErrorUrls, FormData } from '../../services/errors';
import usersApi from '../../services/users';
import vehiclesApi from '../../services/vehicles';

import EditComponent, { Option, Types } from './EditComponent';
import {
  englishString,
  grey,
  Forms,
  usePagination,
  Driver,
} from '@mgk-eld/utils';
import {
  CustomButton,
  CustomPagination,
  DataTable,
  YYYY_MM_DD_format,
} from '@mgk-eld/core';

export type DraftData = {
  vehicleId?: number;
  coDriverId?: number;
  coDriverName?: string;
  vehicle?: string;
  trailer?: string;
  shippingDoc?: string;
};

export type Draft = {
  [id: number]: DraftData;
};

const DriverForms: React.FC<{
  date: string | undefined;
  driverName?: string;
  driverId?: number;
  setCoDriver?: React.Dispatch<React.SetStateAction<Driver | null>>;
}> = ({ date, driverName, driverId, setCoDriver }) => {
  const [forms, setForms] = useState<Forms[]>([]);
  const [draft, setDraft] = useState<Draft>({});
  const [loading, setLoading] = useState(false);
  const { page, size, count, setSize, setTotal, handlePageChange } =
    usePagination();
  const selectedCompany = useAppSelector(getSelectedCompanyId);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const loadData = useCallback(async () => {
    if (!selectedCompany) return;
    setLoading(true);
    const { data } = await errorsApi.load(
      ErrorUrls.DriverForms,
      {
        page,
        size,
        total: null,
      },
      {
        company_id: selectedCompany,
        date,
        driver_name: driverName,
        driver_id: driverId,
      }
    );
    setLoading(false);
    setForms(data.items);
    setTotal(data.total);
    const coDriver = data?.items?.[0]?.codriver;
    if (setCoDriver) setCoDriver(coDriver ? coDriver : null);
  }, [
    date,
    driverId,
    driverName,
    page,
    selectedCompany,
    setCoDriver,
    setTotal,
    size,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const getDraftData = useCallback(
    (id: number, field: keyof DraftData, value: string | number | null) => {
      return draft[id]?.[field] ?? value;
    },
    [draft]
  );

  const handleSign = useCallback(
    async (row: any, isSave: boolean) => {
      if (!selectedCompany) return;
      const payload: FormData = {
        company_id: selectedCompany,
        driver_id: row.id,
        date: date ?? dayjs(new Date()).format(YYYY_MM_DD_format),
        data: {
          signed: isSave ? row.signed : true,
          vehicle_id: row.vehicle_id,
          codriver_id: row.coDriverId,
          trailer: row.trailer,
          shipping_docs: row.shippingDoc,
        },
      };

      try {
        await errorsApi.updateForm(payload);
        toast.success(isSave ? 'Form saved' : 'Form signed');
        loadData();
        setDraft({});
      } catch (error) {
        console.error(error);
      }
    },
    [date, loadData, selectedCompany]
  );

  const setValue = useCallback((id: number, data: DraftData) => {
    setDraft((draft) => {
      const currentDraft = draft[id] ?? {};
      return {
        ...draft,
        [id]: {
          ...currentDraft,
          ...data,
        },
      };
    });
  }, []);

  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'driver',
        headerName: 'DRIVER',
        flex: isDesktop ? 1.5 : undefined,
        headerAlign: 'center',
        sortable: false,
      },
      {
        field: 'vehicle',
        headerName: 'VEHICLE',
        flex: isDesktop ? 1 : undefined,
        headerAlign: 'center',
        sortable: false,
        renderCell: (params) => (
          <EditComponent
            id={params.row.id}
            setValue={(val: string | Option) =>
              typeof val !== 'string' &&
              setValue(params.row.id, {
                vehicleId: val?.value,
                vehicle: val?.label,
              })
            }
            value={params.value}
            searchField="unit_number"
            type={Types.Vehicles}
            getOptions={vehiclesApi.getVehicles}
          />
        ),
      },
      {
        field: 'trailer',
        headerName: 'TRAILER',
        flex: isDesktop ? 0.5 : undefined,
        headerAlign: 'center',
        sortable: false,
        renderCell: (params) => (
          <EditComponent
            id={params.row.id}
            validations={englishString
              .max(32)
              .matches(/^[^ ]*( [^ ]*[^ ]*( [^ ]*[^ ]*)?)?$/)} // at most 2 spaces are allowed
            setValue={(val: string | Option) =>
              typeof val === 'string' &&
              setValue(params.row.id, {
                trailer: val,
              })
            }
            value={params.value}
          />
        ),
      },
      {
        field: 'shippingDoc',
        headerName: 'SHIPPING DOC',
        flex: isDesktop ? 0.7 : undefined,
        headerAlign: 'center',
        sortable: false,
        renderCell: (params) => (
          <EditComponent
            id={params.row.id}
            setValue={(val: string | Option) =>
              typeof val === 'string' &&
              setValue(params.row.id, {
                shippingDoc: val,
              })
            }
            validations={englishString.max(40)}
            value={params.value}
          />
        ),
      },
      {
        field: 'coDriver',
        headerName: 'CO-DRIVER',
        flex: isDesktop ? 1.2 : undefined,
        headerAlign: 'center',
        sortable: false,
        renderCell: (params) => (
          <EditComponent
            id={params.row.id}
            setValue={(val: string | Option) =>
              typeof val !== 'string' &&
              setValue(params.row.id, {
                coDriverId: val?.value,
                coDriverName: val?.label?.split(' ').slice(0, 2).join(' '),
              })
            }
            value={params.value}
            searchField="name"
            type={Types.CoDrivers}
            getOptions={usersApi.getUsers}
          />
        ),
      },
      {
        field: 'signed',
        headerName: 'SIGNED',
        align: 'left',
        flex: isDesktop ? 0.5 : undefined,
        headerAlign: 'center',
        sortable: false,
      },
      {
        field: 'save',
        align: 'center',
        flex: isDesktop ? 0.7 : undefined,
        sortable: false,
        renderHeader: () => null,
        renderCell: ({ row }) => {
          return (
            <CustomButton
              onClick={() => handleSign(row, true)}
              size="small"
              width={isDesktop ? undefined : 72}
              style={{
                backgroundColor: grey[800],
              }}
            >
              Save
            </CustomButton>
          );
        },
      },
      {
        field: 'sign',
        align: 'center',
        flex: isDesktop ? 0.7 : undefined,
        sortable: false,
        renderHeader: () => null,
        renderCell: ({ row }) => {
          const id = row.id;
          const getIsDraftExists = (id: number) => {
            return draft[id] && Object.keys(draft[id]).length !== 0;
          };
          return (
            <Tooltip
              placement="top"
              title={
                row.autocert
                  ? 'Advanced certification is ON for this driver'
                  : ''
              }
            >
              <span>
                <CustomButton
                  selected={row.signed === 'Yes' && !getIsDraftExists(id)}
                  onClick={() => handleSign(row, false)}
                  disabled={
                    row.autocert ||
                    (row.signed === 'Yes' && !getIsDraftExists(id))
                  }
                  size="small"
                  width={isDesktop ? undefined : 72}
                >
                  {row.signed === 'Yes' && !getIsDraftExists(id)
                    ? 'Signed'
                    : 'Sign'}
                </CustomButton>
              </span>
            </Tooltip>
          );
        },
      },
    ],
    [draft, handleSign, isDesktop, setValue]
  );

  const rows = useMemo(
    () =>
      forms.map((form) => ({
        id: form.driver.id,
        driver: `${form.driver.name} ${form.driver.surname}`,
        vehicle: getDraftData(form.driver.id, 'vehicle', form.vehicle_number),
        coDriver: getDraftData(
          form.driver.id,
          'coDriverName',
          form.codriver
            ? `${form.codriver.name} ${form.codriver.surname}`
            : null
        ),
        coDriverId:
          draft[form.driver.id]?.coDriverId === undefined
            ? form.codriver?.id
            : draft[form.driver.id]?.coDriverId,
        trailer: getDraftData(form.driver.id, 'trailer', form.trailer),
        shippingDoc: getDraftData(
          form.driver.id,
          'shippingDoc',
          form.shipping_docs
        ),
        vehicle_id: draft[form.driver.id]?.vehicleId ?? form.vehicle_id,
        signed: form.signed ? 'Yes' : 'No',
        autocert: form.autocert,
      })),
    [draft, forms, getDraftData]
  );

  return (
    <>
      <DataTable
        disableRowSelectionOnClick
        autoHeight
        loading={loading}
        checkboxSelection={false}
        rows={rows}
        columns={cols}
        columnVisibilityModel={{ driver: isDesktop }}
      />
      {count > 0 && (
        <CustomPagination
          count={count}
          handlePageChange={handlePageChange}
          size={size}
          setSize={setSize}
          page={page}
          withSizeOptinos={isDesktop}
        />
      )}
    </>
  );
};

export default DriverForms;
