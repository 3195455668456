import vehiclesApi from '../../services/vehicles';
import { AppThunk } from '../store';
import { setVehicles } from './slice';

export const fetchAndSetVehicles =
  (company_id: number): AppThunk =>
  async (dispatch, getState) => {
    const { data } = await vehiclesApi.getVehicles({
      company_id,
      page: 1,
      size: 100,
      include_deleted: true,
    });
    dispatch(setVehicles(data.items));
  };
